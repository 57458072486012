<template lang="pug">
     v-container
        v-card.pointer(v-if="!loading" v-for="project in projects" :key="project.id" class="mx-auto mb-1 pointer" max-width="400" @click.native="goToProject(project)")
            v-card-title {{ project.name }}

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}



</template>


<script>
    import { projectService } from '@/_services'
    import { mapMutations, mapState } from "vuex";

    export default {
        data: () => ({
            projects:[],
            loading:false,
            error: null
        }),

        mounted() {
            this.loadProjects()
        },

        computed: {
            ...mapState("project", ["selectedProject"])
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("project", ["setSelectedProject"]),
            loadProjects(){
                this.loading = true
                this.setAppBarTitle('loading')
                projectService.getMyProjects()
                    .then(data => {
                        this.projects = data
                        this.loading = false
                        this.setAppBarTitle('Проекты')
                    })
                    .catch(error => {
                        this.loading = false
                        this.error = error
                        this.setAppBarTitle(this.error)
                    })
            },

            goToProject(p){
                this.setSelectedProject(p)
                this.$router.push(`/project/${p.id}`)
            }
        }
    }
</script>